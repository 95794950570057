import React from "react"
import { Link } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"
import { connectStateResults } from "react-instantsearch/connectors"
import "./search.css"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
)

const Hit = ({ hit }) => {
  let path = `/${hit.slug}/` // default, a section index page

  if (hit.section) {
    if (hit.section.slug === "/") {
      // root section
      if (hit.slug === "") {
        path = `/` // home page
      } else {
        path = `/${hit.slug}/` // root page
      }
    } else {
      path = `/${hit.section.slug}/${hit.slug}/` // standard page
    }
  }

  return hit.section ? (
    <Link className="hit" to={path}>
      <strong>
        <Highlight attribute="title" hit={hit} />
      </strong>
      <br />
      <Snippet attribute="mainContent" hit={hit} />
    </Link>
  ) : (
    <Link className="hit" to={path}>
      <strong>
        <Highlight attribute="title" hit={hit} />
      </strong>
      <br />
      Section index page.
    </Link>
  )
}

const Content = connectStateResults(({ searchState, searchResults }) => {
  const hasQuery = searchState && searchState.query

  return hasQuery ? (
    <div style={{ overflow: "hidden" }}>
      <Hits hitComponent={Hit} />
      <PoweredBy />
    </div>
  ) : (
    <></>
  )
})

const Search = () =>
  typeof window !== "undefined" ? (
    <InstantSearch
      indexName={process.env.GATSBY_ALGOLIA_INDEX}
      searchClient={searchClient}
    >
      <SearchBox
        translations={{ placeholder: "Search the Readability Guidelines" }}
      />
      <Content />
    </InstantSearch>
  ) : (
    <div class="ais-SearchBox">
      <form
        method="get"
        action="https://www.google.com/webhp?q=site:readabilityguidelines.co.uk"
        className="ais-SearchBox-form"
      >
        <input
          placeholder="Search the Readability Guidelines"
          type="search"
          name="q"
          className="ais-SearchBox-input"
        />
        <input
          type="hidden"
          name="sitesearch"
          value="readabilityguidelines.co.uk"
        />
        <button
          type="submit"
          title="Submit your search query."
          class="ais-SearchBox-submit"
        >
          <svg
            class="ais-SearchBox-submitIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 40 40"
          >
            <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
          </svg>
        </button>
      </form>
    </div>
  )

export default Search
