import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { ReactComponent as Logo } from "../images/logo.svg"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <Link to="/" title="Go to Readability Guidelines home page">
        <span className="logo">
          <Logo />
        </span>
      </Link>
      <p className="visually-hidden--sml">
        An award-winning collaborative, global project.
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
