/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <div className="container" style={{ marginBottom: 0 }}>
        <p
          style={{
            fontSize: "1.125rem",
            marginBottom: 0,
            background: "#fcf8e3",
            padding: "1rem",
          }}
        >
          This wiki is no longer being updated. But it is still available for anyone who wants to make style decisions based on evidence and data.
        </p>
      </div>

      <main>{children}</main>

      <footer>
        <div className="container">
          <ul>
            <li>
              <a href="https://contentdesign.london/">Content Design London</a>
            </li>
            <li>
              <a href="https://contentdesign.london/privacy">Privacy</a>
            </li>
          </ul>

          <hr />
          <p className="small">© 2024 Copyright. Content Design London</p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
